import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';

import { PhutureIavatarComponent } from './phuture-iavatar.component';

import { NgxFaceAvatarModule } from 'ngx-faceavatar';

@NgModule({
  declarations: [PhutureIavatarComponent],
  imports: [CommonModule, BrowserModule, NgxFaceAvatarModule],
  exports: [PhutureIavatarComponent],
})
export class PhutureIavatarModule implements DoBootstrap {
  constructor(private injector: Injector) {}

  ngDoBootstrap(): void {
    const el = createCustomElement(PhutureIavatarComponent, {
      injector: this.injector,
    });

    customElements.define('phuture-iavatar', el);
  }
}
