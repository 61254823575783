import { Injectable } from '@angular/core';
import { tap, map } from 'rxjs/operators';
import { retry, catchError } from 'rxjs/operators';

import { AuthStore } from './auth.store';
import { AuthHttpService } from './auth-http-service';
import { AuthQuery } from '@app/auth/auth.query';
import { resetStores } from '@datorama/akita';

@Injectable({
  providedIn: 'root',
})
export class AuthDataService {
  arMonate = [];

  constructor(private authStore: AuthStore, private authHttpService: AuthHttpService, private authQuery: AuthQuery) {}

  login(creds: any) {
    return this.authHttpService.login(creds).pipe(
      tap((data) => {
        // we have to do some mapping
        // as the return is more than
        // we want in the auth store
        let auth = {
          access_token: data.access_token,
          token_type: data.token_type,
          expires_in: data.expires_in,
          refresh_token: data.refresh_token,

          name: data.name,
          company: data.company,
          email: data.email,
          role: data.role,
          profile_image: data.profile_image,

          firststart: data.firststart,
          firststart_done: data.firststart_done,
          version: data.version,
          appdata: data.appdata,
          userid: data.userid,
          fid: data.fid,

          isactivated: data.isactivated,
          isapproved: data.isapproved,
          status: data.status,

          country: data.country,
        };

        this.authStore.save(auth);
      })
    );
  }

  register(data: any) {
    return this.authHttpService.register(data).pipe(
      tap((data) => {
        // we have to do some mapping
        // as the return is more than
        // we want in the auth store
        let auth = {
          access_token: data.access_token,
          token_type: data.token_type,
          expires_in: data.expires_in,
          refresh_token: data.refresh_token,

          name: data.name,
          company: data.company,
          email: data.email,
          role: data.role,
          profile_image: data.profile_image,

          firststart: data.firststart,
          firststart_done: data.firststart_done,
          version: data.version,
          appdata: data.appdata,
          userid: data.userid,
          fid: data.fid,

          isactivated: data.isactivated,
          isapproved: data.isapproved,
          status: data.status,

          country: data.country,
        };

        this.authStore.save(auth);
      })
    );
  }

  firststart(firststartData: any) {
    // do the login actions
    return this.authHttpService.firststart(firststartData).pipe(
      tap((data) => {
        // we have to do some mapping
        // as the return is more than
        // we want in the auth store
        let auth = {
          access_token: data.access_token,
          token_type: data.token_type,
          expires_in: data.expires_in,
          refresh_token: data.refresh_token,

          name: data.name,
          company: data.company,
          email: data.email,
          role: data.role,
          profile_image: data.profile_image,

          firststart: data.firststart,
          firststart_done: data.firststart_done,
          version: data.version,
          appdata: data.appdata,
          userid: data.userid,
          fid: data.fid,

          isactivated: data.isactivated,
          isapproved: data.isapproved,
          status: data.status,

          country: data.country,

          visitor: data.visitor,
          exhibitor: data.exhibitor,
        };
        this.authStore.save(auth);
      })
    );
  }

  updateVersion(version: any) {
    this.authStore.update({ version: version });
  }

  updateFirststart(firststart: any) {
    this.authStore.update({ firststart: firststart });
  }

  changepwd(data: any) {
    return this.authHttpService.changePassword(data).pipe(
      tap((data) => {
        // we have to do some mapping
      })
    );
  }

  forgotpwd(data: any) {
    return this.authHttpService.forgotPassword(data).pipe(
      tap((data) => {
        // we have to do some mapping
      })
    );
  }

  logout() {
    return this.authHttpService.logout().pipe(
      tap((auth) => {
        //console.log('remove all stores');
        this.authStore.remove();
        resetStores();
      })
    );
  }
}
