import { FormGroup, FormArray, AbstractControl, ValidatorFn } from '@angular/forms';

export function requireAtLeastOneCheckboxValidator(minRequired = 1): ValidatorFn {
  return (formGroup: AbstractControl) => {
    const fGroup = formGroup as FormGroup;

    let checked = 0;

    Object.keys(fGroup.controls).forEach((key) => {
      const control = fGroup.controls[key];

      if (control.value === true) {
        checked++;
      }
    });

    if (checked < minRequired) {
      return {
        requireAtLeastOneCheckboxValidator: true,
      };
    }
    return null;
  };
}
