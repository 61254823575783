import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';
import { Observable, EMPTY, throwError, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { MatSnackBar } from '@angular/material/snack-bar';

import { environment } from '@env/environment';
import { Logger } from '../logger.service';

const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private _snackBar: MatSnackBar) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    //console.log('ErrorHandlerInterceptor');
    return next
      .handle(request)
      .pipe(catchError((error) => this.handleError(error)));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );

      // error.status == 504
      if (error.status == 401) {
        this._snackBar.open(
          'Your permission is not sufficient to view the requested ressource. Please contact the System Administrator.',
          'Close',
          {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 3000,
          }
        );
      } else {
        // error.status == 504
        if (error.status < 200 || error.status > 350) {
          /*|| error.status != 'undefined' */
          this._snackBar.open(
            'Die Internet-Verbindung ist nicht stabil. Sie können weiterarbeiten, doch kann es zu Verzögerungen kommen.',
            'Schließen',
            {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 3000,
            }
          );
        }
      }
    }
    // Return an observable with a user-facing error message.
    return throwError('Something bad happened; please try again later.');
  }

  /*
  // Customize the default error handler here if needed
  private errorHandler(error: HttpEvent<any>): Observable<HttpEvent<any>> {

    if (error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error}, body was: ${error}`);
    }

    // If you want to return a new response:
    //return of(new HttpResponse({body: [{name: "Default value..."}]}));

    // If you want to return the error on the upper level:
    //return throwError(error);

    // or just return nothing:
    return EMPTY;

    if (!environment.production) {
      // Do something with the error
      log.error('Request error', error);
    }
    throw error;
  }
  */
}
