import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { map, take, switchMap, tap } from 'rxjs/operators';
import { Observable, of, from, timer } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

import { AuthQuery } from './auth.query';

import { JournalService } from '@app/@core';
import { Logger } from '@core';
const log = new Logger('AuthenticationGuard');

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  constructor(
    private router: Router,
    private authQuery: AuthQuery,
    private cookieService: CookieService,
    private journalService: JournalService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authQuery.isLoggedIn) {
      // set a cookie for the current day

      var wasHere = this.cookieService.get('logged-on-today');
      if (wasHere == '1') {
        // everything fine
        //console.log("was here");
      } else {
        this.cookieService.set('logged-on-today', '1', {
          expires: 60 * 60 * 12,
        });
        //console.log('login today');
        this.journalService.log('Anmeldung mit Remember Me Funktion    --------------------------------------');
      }

      // now check if the first start configuration wizzard has to be activated
      // check firststart
      //console.log("value: " + this.appQuery.getValue().appdata);

      //let xappData = this.adQuery.selectFirst().subscribe((data) => {
      //  console.log(data);
      //});
      /*
      return this.adQuery.selectFirst().pipe(
        map((data) => {
          //console.log(data);

          let appData = <IAppDataModel>data;

          return true;

          if (String(appData.activation.firststart_wizard).toLowerCase() == "true") {
            // setup wizzard is done
            console.log("it is done (true): " + appData.activation.modul1_paid);
            return true;
          } else {
            // setup wizzard is not done
            console.log("it is not done (false): " + appData.activation.modul1_paid);
            this.router.navigate(['/firststart'], { queryParams: { redirect: state.url }, replaceUrl: true });
            return true;
          }
        })
      );
      */

      //
      // check db and sync if necessary
      //
      //this._adQuery.selectEntity(this.authQuery.appid).subscribe((state) => {
      //console.log(state);
      //this.appData = state;
      /*
        BUG Situation: canActivate does not wait until post is resolved
        ---> different solution needed
      console.log('check sync');
      this._adService.getAppDataVersion().subscribe((results) => {
        //console.log(results);
      });

      this._adService.getAppDataVersion().pipe(
        map((response) => {
          response.status === 'success';
          console.log(response);
        }),
        catchError((error) => of(false))
      );
      */

      /*
        var data = this._adService.getAppDataVersion(state).subscribe((data) => {
          console.log(data);
          debugger;
          console.log('current version: ' + this.authQuery.version + '/' + data.version);
          if (this.authQuery.version < data.version) {
            console.log('new version');
          }
        });
        */
      //this.cAppData = JSON.parse(JSON.stringify(this.appData));
      //});

      // check if firststart was done
      let firststart = true; //this.authQuery.firststart;
      if (firststart) {
        //console.log("it is done (true): " + firststart);
        return true;
      } else {
        // setup wizzard is not done
        //console.log("it is not done (false): " + firststart);
        this.router.navigate(['/firststart'], {
          queryParams: { redirect: state.url },
          replaceUrl: true,
        });
        return false;
      }

      //return true;
    } else {
      // always redirect to login
      log.debug('Not authenticated, redirecting and adding redirect url...');
      this.router.navigate(['/'], {
        queryParams: { redirect: state.url },
        replaceUrl: true,
      });
      return true;
    }
  }
}
