import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { timer, throwError } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { tap, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@env/environment';

import { AuthQuery } from '@app/auth/auth.query';

export interface Mail {
  subject: string;
  message: string;
}
const HttpUploadOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class MailService {
  constructor(private http: HttpClient, private authQuery: AuthQuery) {}

  public send(mail: Mail) {
    let input = new FormData();
    // Add your values in here
    input.append('uid', '' + this.authQuery.userid);
    input.append('subject', mail.subject);
    input.append('message', mail.message);

    var data = {
      uid: '' + this.authQuery.userid,
      subject: mail.subject,
      message: mail.message,
      token: '12987361235283447283261',
    };

    // rest api call
    return this.http
      .post<any>(
        `${environment.serverUrl}/emailer`,
        JSON.stringify(data),
        HttpUploadOptions
      )
      .pipe(
        map((data) => {
          //console.log(data);
          return data;
        })
      );
  }

  private handleError(error: Response | any) {
    console.error('ApiService::handleError', error);
    return throwError(() => error);
  }
}
