import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { timer, throwError } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { tap, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@env/environment';

import { AuthQuery } from '@app/auth/auth.query';

interface Journal {
  userid: string;
  aktion: string;
  token: string;
}
const HttpUploadOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class JournalService {
  constructor(private http: HttpClient, private authQuery: AuthQuery) {}

  public log(aktion: string) {
    let input = new FormData();
    // Add your values in here
    input.append('uid', '' + this.authQuery.userid);
    input.append('aktion', aktion);
    input.append('token', '' + this.authQuery.access_token);

    var data = {
      uid: '' + this.authQuery.userid,
      aktion: aktion,
      token: '' + this.authQuery.access_token,
    };

    // rest api call
    /*
    return this.http.post<any>(`${environment.serverUrl}/journal`, JSON.stringify(data), HttpUploadOptions).pipe(
      map((data) => {
        //console.log(data);
        return data;
      })
    );
    */

    //
    // second version - as the 1st did not work properly
    //

    /*
    const xhr = new XMLHttpRequest();

    // listen for `load` event
    xhr.onload = () => {
      // print JSON response
      if (xhr.status >= 200 && xhr.status < 300) {
        // parse JSON
        const response = JSON.parse(xhr.responseText);
        //console.log(response);
      }
    };

    // open request
    xhr.open('POST', `${environment.serverUrl}/journal`);

    // set `Content-Type` header
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    //xhr.setRequestHeader('Accept', "application/json");
    xhr.setRequestHeader('Access-Control-Allow-Headers', '*');

    // token
    xhr.setRequestHeader(
      'Authorization',
      `Bearer ${this.authQuery.access_token}`
    );

    // send rquest with JSON payload
    xhr.send(JSON.stringify(data));

      */
  }

  private handleError(error: Response | any) {
    console.error('ApiService::handleError', error);
    return throwError(() => error);
  }
}
