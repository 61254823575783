import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticationGuard } from '@app/auth/authentication.guard';

import { PhutureLoginComponent } from './phuture-login/phuture-login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FirststartComponent } from './firststart/firststart.component';
import { GalleryComponent } from './gallery/gallery.component';

import { ProfileExhibitorComponent } from './profile-exhibitor/profile-exhibitor.component';

const routes: Routes = [
  //{ path: '', redirectTo: 'atout', pathMatch: 'full' }, // atout project start ---> login etc.
  { path: '', redirectTo: 'gallery', pathMatch: 'full' }, // atout project end ----> gallery
  /*
  { dieser pfad wird nicht benötigt - denk ich
    path: '',
    canActivate: [],
    component: PhutureLoginComponent,
    data: { title: 'Atout' },
  },
  */
  {
    path: 'atout',
    canActivate: [],
    component: PhutureLoginComponent,
    data: { title: 'Atout' },
  },

  {
    path: 'gallery',
    canActivate: [],
    component: GalleryComponent,
    data: { title: 'Gallery' },
  },

  {
    path: 'home',
    canActivate: [AuthenticationGuard],
    component: DashboardComponent,
    data: { title: 'Home' },
    // List and form component is the child component of sidebar3 component.
    children: [
      {
        // When you click go to list, it navigate to list component page.
        path: 'edit',
        component: FirststartComponent,
      },
    ],
  },

  // this firststart routine is only for the visitors
  {
    path: 'firststart',
    canActivate: [AuthenticationGuard], // AppDataSyncGuard
    component: FirststartComponent,
    data: { title: 'Home' },
  },

  // this firststart routine is only for the visitors

  {
    path: 'profile-exhibitor',
    canActivate: [], // AuthenticationGuard,AppDataSyncGuard
    component: ProfileExhibitorComponent,
    data: { title: 'Home' },
  },

  /*
    {
      path: 'settings',
      canActivate: [],
      component: SettingsComponent,
      data: { title: 'Settings' },
    },

    {
      path: 'datenschutz',
      canActivate: [],
      component: DatenschutzComponent,
      data: { title: 'Datenschutz' },
    },
    {
      path: 'impressum',
      canActivate: [],
      component: ImpressumComponent,
      data: { title: 'Impressum' },
    },
    */
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
