import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpParamsOptions, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { environment } from '@env/environment';

import { map, tap } from 'rxjs/operators';

import { SortDirection } from '@angular/material/sort';

/*
const HttpUploadOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
  }),
};
*/

export interface VisitorStats {
  nbr_visitors: number;
  nbr_exhibitors: number;
  nbr_appointments: number;
  nbr_registered: number;
  nbr_approved: number;
  nbr_fs_done: number;
}

export interface Visitor {
  t_visitorid: number;
  t_profileid: number;
  t_visitor_propid: number;

  profile_image: string;

  company: string;
  emailincharge: string;
  companymail: string;
  firstname: string;
  lastname: string;
  position: string;
  country: string;
  zusatz1: string;
  zusatz2: number;
  adress: string;
  areacode: string;
  city: string;
  companyphone: string;
  website: string;
  icphone: string;
  generalisttour: number;
  specialisttour: number;
  tourownretailing: number;
  tourretailingtravelagencies: number;
  touroperatormember: number;
  travel: number;
  incentive: number;
  inernationalincoming: number;
  coach: number;
  carrental: number;
  hotelbookingcallcenter: number;
  aircarrier: number;
  seacarrier: number;
  roadcarrier: number;
  railcarrier: number;
  numberemployees: number;
  volumegroups: number;
  volumeindividuals: number;
  numberbrochures: number;
  onlinebooking: number;
  chkcity1: number;
  chkcity2: number;
  palsace: number;
  pauvergne: number;
  pbrittany: number;
  pburgundy: number;
  pcorsica: number;
  pchampagne: number;
  ploirevalley: number;
  pnordpasdecalais: number;
  pnormandy: number;
  plimousin: number;
  pfranche: number;
  plancuedoc: number;
  poverseas: number;
  pparis: number;
  pwesternloire: number;
  pprovence: number;
  pall: number;
  pobusiness: number;
  pobeach: number;
  pocruises: number;
  pobarging: number;
  pogolf: number;
  poyouth: number;
  posummer: number;
  ponature: number;
  poreligious: number;
  powinter: number;
  pocultural: number;
  pogastronomy: number;
  pourban: number;
  poactive: number;
  pochampagne: number;
  pofitness: number;
  sauvergne: number;
  sbrittany: number;
  sburgundy: number;
  scorsica: number;
  schampagne: number;
  sloire: number;
  snordpasdecalais: number;
  snormandy: number;
  slimousin: number;
  sfranche: number;
  slanguedoc: number;
  soverseas: number;
  sparis: number;
  swesternloire: number;
  sprovence: number;
  sall: number;
  khotelgroup: number;
  k2starhotel: number;
  k3starhotel: number;
  k4starhotel: number;
  palacehotels: number;
  kvacation: number;
  kholidayclub: number;
  kbandb: number;
  krestaurant: number;
  kincomingagency: number;
  kcongress: number;
  gsightseeing: number;
  kairtrain: number;
  kcoach: number;
  kbarging: number;
  kleisure: number;
  kmuseum: number;
  kshow: number;
  kmall: number;
  ktouristoffice: number;
  danydietryallergies: string;

  storno_accepted: number;
  dsgvo_accepted: number;
  agb_accepted: number;
  media_accepted: number;
  special_wishes: number;
  uid: number;
  garage_day1: number;
  garage_day2: number;
  lunch_day1: number;
  lunch_day2: number;
  attendance_event: number;
  hotel_stay_night: number;
  transfer_arrival: number;
  transfer_return: number;
  data1: number;
  flight_booked: number;
  data2: string;

  decharge_resp_civile: number;
  estimate: string;

  activated: number;
  approved: number;
  firststart_done: number;

  convention_fee_paid: number;
  hotel_booked: number;
  fully_booked: number;

  beuser_id: number;
  notes: string;
}

export interface VisitorSingle {
  data: Visitor;
  stats: VisitorStats;
  page: number;
  per_page: number;
  total: number;
  total_pages: number;
}

export interface VisitorTable {
  data: Visitor[];
  stats: VisitorStats;
  page: number;
  per_page: number;
  total: number;
  total_pages: number;
}

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    // 'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Origin': '*',
  }),
};

//
// --------------------------------------------------------------
//

@Injectable({
  providedIn: 'root',
})
export class VisitorService {
  errorMsg: string = '';

  constructor(private httpClient: HttpClient) {}

  /**
   * GET ALL
   *
   * @return response()
   */

  public getVisitors(
    pageNumber: Number,
    pageSize: Number,
    sort: string,
    order: SortDirection,
    searchTerm: string,
    filtersArgs: string
  ): Observable<VisitorTable> {
    const apiUrl = `${environment.serverUrl}/visitor`;
    // '?page=${pageNumber}&per_page=${pageSize}&sort=${sort}&sorder=${order}`

    return this.httpClient.get<VisitorTable>(apiUrl, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
      }),
      params: new HttpParams()
        .set('page', '' + pageNumber)
        .set('per_page', '' + pageSize)
        .set('sort', sort)
        .set('sorder', order)
        .set('sterm', searchTerm)
        .set('filters', filtersArgs),
    });

    /*
    .pipe(
      map((response: VisitorTable) => {
        //debugger;
        return response.data.map((item: Visitor) => ({
          ...item,
        }));
      }),
      tap((response: any) => {
        console.log('mrio');
        console.log(response);
        return <Visitor[]>response;
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        } else {
          errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
    */
  }

  /**
   * GET / FIND BY ID
   *
   * @return response()
   */
  getVisitorById(id: number): Observable<VisitorSingle> {
    const apiUrl = `${environment.serverUrl}/visitor`;

    return this.httpClient
      .get<VisitorSingle>(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Headers': '*',
          'Access-Control-Allow-Origin': '*',
        }),
        params: new HttpParams().set('id', '' + id),
      })
      .pipe(
        map((response: VisitorSingle) => {
          return {
            ...response,
          };
        })
      );

    /*
    return this.httpClient.get<Visitor[]>(environment.serverUrl + '/visitor/' + id, httpOptions).pipe(
      tap((resp) => {
        //this.repo.setQuizes( resp || []);
        return resp;
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
    */
  }

  /**
   * GET / FIND BY FILTER
   *
   * @return response()
   */
  getVisitorByParams(data: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    //const data: any = { this: 'thisThing', that: 'thatThing', other: 'otherThing'};

    const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;

    const options = { params: new HttpParams(httpParams), headers: headers };

    return this.httpClient.get<Visitor[]>(environment.serverUrl + '/visitor/', options).pipe(
      tap((resp) => {
        //this.repo.setQuizes( resp || []);
        return resp;
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
  }

  /**
   * CREATE
   *
   * @return response()
   */
  createVisitor(post: any): Observable<any> {
    return this.httpClient.post<any>(environment.serverUrl + '/visitor/', JSON.stringify(post), httpOptions).pipe(
      map((response) => {
        return {
          ...response.data,
          id: response.data.quuid,
          quizdata: '',
        };

        /*
          response.data.map((item) => ({
            ...item,
            id: item.quuid,
            quizdata: ''
          }));

          return response;
          */
      }),
      tap((response: any) => {
        console.log(response);
        //this.repo.addQuiz(response);
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
  }

  /**
   * UPDATE
   *
   * @return response()
   */
  updateVisitor(person: Visitor): Observable<any> {
    let id = person.t_visitorid;
    const data = {
      //'id': quiz.qdata.id,
      quuid: '' + person.t_visitorid,
    };

    // do the update
    /*     return this.httpClient.post(environment.serverUrl + '/person/' + id, data, httpOptions)
      .pipe(
        catchError((res: HttpErrorResponse) => {
            console.log(res);
            return throwError(JSON.stringify(res));

        })
      );*/

    return this.httpClient.post<any>(environment.serverUrl + '/visitor/' + id, JSON.stringify(data), httpOptions).pipe(
      map((response) => {
        console.log('map');
        return {
          ...response.data,
          id: response.data.quuid,
          quizdata: '',
        };
      }),
      tap((response: any) => {
        console.log('tAP');
        //this.repo.updateQuiz(response);
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
  }

  /**
   * DELETE
   *
   * @return response()
   */
  deleteVisitor(id: string) {
    return this.httpClient.delete(environment.serverUrl + '/visitor/' + id, httpOptions).pipe(
      tap((value) => {
        //this.repo.removeQuiz(id);
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
  }

  /**
   * Write code on Method
   *
   * @return response()
   */
  private getServerErrorMessage(error: HttpErrorResponse) {
    console.log('custom service error handler');

    switch (error.status) {
      case 0: {
        // A client-side or network error occurred. Handle it accordingly.
        //console.error('An error occurred:', error.error);
        return `An error occurred: ${error.error}`;
      }
      case 404: {
        return `Not Found: ${error.message}`;
      }
      case 403: {
        return `Access Denied: ${error.message}`;
      }
      case 500: {
        return `Internal Server Error: ${error.message}`;
      }
      default: {
        return `Unknown Server Error: ${error.error}`;
      }

      // Return an observable with a user-facing error message.
      //return throwError(() => new Error('Something bad happened; please try again later.'));
    }
  }
}
