import { env } from './.env';

export const environment = {
  production: true,
  client: 'workshop-france', // france-excellence | french-rendez-vous
  version: env['npm_package_version'],
  serverUrl: 'https://www.workshop-france.de/api/v3',
  eplusSystem: 'https://www.workshop-france.de/eplus',
  defaultLanguage: 'en-EN',
  supportedLanguages: ['en-EN'],
  api_key: 'atout-frech-1234',
  day_1: '25.09.2024',
  day_2: '26.09.2024',
  linkTerms: 'https://www.workshop-france.de/eplus/_fileDB/terms.pdf',
  linkDSGVO: '#',
  linkStorno: '#',
  linkMedia: '#',
  event_year: '2024',
  hotel_info: 'Crowne Plaza Marseille', // lunch at {the DoubleTree by Hilton Vienna Schönbrunn}
  hotel_parking: 'I will need a parking place at the hotel Bayerischer Hof for the', // Yes, I would like Atout France to reserve a parking place in my name for the
  event_space: 'Seehaus Pavillion in the English Garden', // evening at the {Schönbrunn Palace} on 22.05.24
  event_date: '16.10.2024', // evening at the Schönbrunn Palace on {22.05.24}
  book_night: '16.10/17.10.2024', // book a room in my name for the night of {22.05/23.05 2024} at the
  cancel_date: 'September 25th 2024', // for all cancellations after {May 10th 2023}, the
  event_dates: '16th October and 17th October 2024', // {31st May and 1st June 2023} in Vienna - Austria
  veranstaltungs_land: 'de', // country code
  county_selection: false, // true or false: einfachere version (nur veranstaltungsland und andere)
  show_accounting_info: false, // if the button accunting-info should beshown

  //
  /* -----------------*/
  event_finish: false, // true or false if event is done and gallery should be shown
  show_gallery: false, // show the gallery - if false -> show the login

  // video - frontpage
  video_source_dash:
    'https://d2iujko1ysket6.cloudfront.net/videos/ontarget-management-4-austria-7eb7b099/atout-france/AF_2023_MASTER_16-9_DE.mp4/hls/AF_2023_MASTER_16-9_DE.m3u8',
  video_source_webm: './assets/AF_2023_MASTER_16-9_DE.webm',
  video_source_mp4: './assets/AF_2023_MASTER_16-9_DE.mp4',

  //
  /** --------------------------------- */
  txt_EventName: 'WORKSHOP FRANCE',
  txt_Subline: '',
  txt_Event_Domain: '',
  txt_Welcome: '',
  txt_Welcome_Text: '',
  txt_Event_City: '', // Vienna
  txt_Hotel_Night: '', // Hotel Bayerischer Hof
  txt_gallery_location: 'in Marseille - France', // in Vienna - Austria

  // VISITOR DYN FIELDS
  show_dyn_identity: [true, false, false, true, true],
  caption_dyn_identity: {
    carrental: 'Car rental company',
    aircarrier: 'Air carrier',
    seacarrier: 'Sea carrier',
    roadcarrier: 'Corporate', // Road carrier
    railcarrier: 'PCO', // Rail carrier
  },
  show_dyn_programming: [true, false, true, true],
  caption_dyn_programming: {
    spicardy: 'dyn caption a',
    spoitou: 'dyn caption b',
    srhonealps: 'dyn caption c',
    sfrenchriviera: 'dyn caption d',
  },
  show_dyn_products: [],
  caption_dyn_products: {
    pobusiness: '',
    poreligious: '',
    poyouth: '',
    posummer: '',
    paquitaine: '',
    plorraine: '',
    pmidi: '',
    ppicardy: '',
  },
  show_dyn_regions: [false, true, true, false],
  caption_dyn_regions: {
    ppoitou: 'dyn caption e',
    prhonealps: 'dyn caption f',
    pfrenchriviera: 'dyn caption g',
    salsace: 'dyn caption h',
  },
  show_dyn_productsreq: [true, false, true, true, false, false, true, true, false],
  caption_dyn_productsreq: {
    k2starhotel: '2-star hotel',
    k3starhotel: '3-star hotel',
    k4starhotel: '4-star',
    kleisure: 'Leisure or entertainment park',
    kshow: 'Show, cabaret, theater',
    saquitaine: '5-star hotel',
    slorraine: 'Wine / gastronomy tours f',
    smidi: 'Concierge',
    allergies: 'Activities',
  },

  // EXHIBITORS DYN FIELDS
  show_dyn_regions_ex: [true, false, true, true, true],
  caption_dyn_regions_ex: {
    alsace: 'dyn caption a',
    limousin: 'dyn caption b',
    lorraine: 'dyn caption c',
    midipyrenes: 'dyn caption d',
    poitoucharentes: 'dyn caption e',
  },
  show_dyn_activities_ex: [true, true, false, false, false],
  caption_dyn_activities_ex: {
    rhonealpes: 'Croisères',
    riveria: 'Oenotourisme',
    monaco: 'dyn caption g',
    thaiti: 'dyn caption h',
    luxury: 'dyn caption i',
  },

  //
  // EVENT CATEGORIES
  //
  EVENT_CATEGORIES: [
    'Accommodation',
    'Boating Holidays',
    'Carriers',
    'Cultural Sites',
    'Excursions / Entertainment',
    'Incoming services',
    'Luxury offers',
    'MICE Services',
    'Ski',
    'Tourist offices',
    'Shopping',
    'Wine Tourism',
    'Cruises',
    'Others',
  ],
};
