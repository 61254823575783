<div id="logo" class="logo">
  <img src="./assets/logo-france.jpg" alt="Atout France" class="brand" />
</div>

<section (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave($event)">
  <video
    id="video"
    poster="./assets/video-preview.jpg"
    autoplay
    playsinline
    autoplay="autoplay"
    [muted]="mute"
    loop="loop"
    *ngIf="isTablet || isDesktop">
    <source [src]="video_source_dash" type="application/x-mpegURL" />
    <source [src]="video_source_webm" type="video/webm" />
    <source [src]="video_source_mp4" type="video/mp4" />
  </video>

  <div class="bg-image" *ngIf="isMobile" style="background-image: url('./assets/video-preview.jpg')">&nbsp;</div>

  <div *ngIf="isTablet">
    <div class="container-center-center">
      <button mat-button mat-raised-button color="primary" type="button" (click)="goLogin()">Explore Me</button>
    </div>
  </div>

  <div class="overlay" [@slideInOut]="backdropState">
    <div class="video-gradient"></div>
    <div class="video-pattern"></div>
  </div>

  <div class="controlbar" *ngIf="isDesktop || isTablet">
    <button mat-fab color="primary" aria-label="Example icon button with a delete icon" (click)="togglePlay()">
      <mat-icon>{{ playingIcon }}</mat-icon>
    </button>
    &nbsp;
    <button mat-fab color="accent" aria-label="Example icon button with a bookmark icon" (click)="toggleMute()">
      <mat-icon>{{ muteIcon }}</mat-icon>
    </button>
  </div>

  <div [@slideInOut]="animationState" #contentform class="myForm">
    <section class="section-border border-primary" style="position: relative; z-index: 100">
      <div class="container d-flex flex-column">
        <div class="row align-items-center justify-content-center gx-0 min-vh-100">
          <div
            class="col-12 col-md-6 col-lg-{{ colLarge }} french-border-top
            col-xl-{{ colXLarge }} py-8 py-md-11 wrap mat-elevation-z4 myLoginForm">
            <div class="example-small-box text-center">
              <!-- Heading -->
              <h1 class="mb-0 text-center">{{ _event_name }} {{ _eventYear }}</h1>

              <!-- Text -->
              <p>&nbsp;</p>
              <p>{{ _eventDates }} {{ _gallery_location }} <br /></p>
              <h2>
                THANK YOU<br />
                for your participation!
              </h2>

              <div *ngIf="user_id > 0">
                <p>
                  We hope to see you again next year.<br />
                  <br />
                  Meanwhile you can enjoy here the photo galleries of this great event!<br />
                </p>
                <br />

                <button mat-button mat-raised-button color="primary" type="button" (click)="goGallery()">Gallery</button>
              </div>
            </div>
          </div>
        </div>
        <!-- / .row -->
      </div>
    </section>
  </div>
</section>
