import { Component, Output, EventEmitter, SimpleChanges, OnInit, OnChanges, HostListener, ViewChild } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { SlideInOutAnimation } from '../animations';
import { ViewEncapsulation, ElementRef, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { tap, map } from 'rxjs/operators';
import { retry, catchError } from 'rxjs/operators';

import { MatSnackBar } from '@angular/material/snack-bar';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { DeviceDetectorService } from 'ngx-device-detector';

import * as tus from 'tus-js-client';

import { environment } from '../../environments/environment';
import { Logger } from '@core';

import { AuthQuery } from '@app/auth/auth.query';

import { JournalService, MailService, Mail } from '@core';
import { CookieService } from 'ngx-cookie-service';

import { AuthDataService } from '@app/auth/auth-data.service';
import { MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS_FACTORY } from '@angular/material/progress-spinner';

import { requireAtLeastOneCheckboxValidator } from '../@shared/validation/require-checkboxes-to-be-checked.validator';

const log = new Logger('Login');

enum myTabs {
  tabWwelcome = 0,
  tabLogin = 1,
  tabRegisterNew = 2,
  tabRegisterSuccess = 5,
  tabForgotPawd = 3,
  tabFirststart = 4,
}

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          opacity: '1',
          'z-index': '100',
        })
      ),
      state(
        'out',
        style({
          opacity: '0',
          'z-index': '100',
        })
      ),
      transition('in => out', animate('300ms ease-in-out')),
      transition('out => in', animate('1000ms ease-in-out')),
    ]),
  ],
})
export class GalleryComponent implements OnInit {
  myVideo: any = null;
  videoContainer: any = null;

  // user
  user_id: number = 0;
  user_name: string = '';

  // video
  video_source_dash = environment.video_source_dash;
  video_source_webm = environment.video_source_webm;
  video_source_mp4 = environment.video_source_mp4;

  // content
  _event_name = environment.txt_EventName;
  _gallery_location = environment.txt_gallery_location;

  // --
  dialogIsShown: boolean = false;
  isToggle: boolean = false;
  imageName: string = '';
  videoTag: any = null;

  playing: boolean = false;
  playingIcon: string = 'pause';
  mute: boolean = true;
  muteIcon: string = 'volume_mute';
  eye: boolean = false;
  eyeIcon: string = 'visibility_off';

  activeTab: number = myTabs.tabWwelcome;

  version: string | null = environment.version;
  error: string | undefined;
  loginForm!: FormGroup;
  registerForm!: FormGroup;
  forgotForm!: FormGroup;
  isLoading = false;

  // firststart
  colLarge = 5;
  colXLarge = 5;
  showWelcome: boolean = true;
  myGroup: string = '';

  firstExhibitorForm: FormGroup;
  submitted = false;

  isLoginClicked = false;
  isRegisterNewClicked = false;
  isForgetClicked = false;

  @ViewChild('myPond') myPond: any;

  feedback: any;

  // acitivities --> exhibitor
  activities_en: any = [];
  activities_fr: any = [];

  // FORM config
  showTerms = true;
  showDSGVO = false;
  showStorno = false;
  showMedia = true;
  linkTerms = environment.linkTerms;
  linkDSGVO = environment.linkDSGVO;
  linkStorno = environment.linkStorno;
  linkMedia = environment.linkMedia;

  showDays = false; // show the day selection info
  _day1 = environment.day_1;
  _day2 = environment.day_2;

  _eventYear = environment.event_year;
  _eventDates = environment.event_dates;

  // firststart DATA
  fsData: any;
  fsShowFiles = false;

  isMobile = false;
  isTablet = false;
  isDesktop = false;

  constructor(
    private element: ElementRef,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private authService: AuthDataService,
    private authQuery: AuthQuery,
    private router: Router,
    private route: ActivatedRoute,
    private journalService: JournalService,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService
  ) {
    // GET THE DEVICE INFO
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktop = this.deviceService.isDesktop();
  }

  ngOnInit(): void {
    //console.log('init');

    // get the user
    console.log('oninit - user');
    this.user_id = this.authQuery.userid;
    this.user_name = this.authQuery.name;

    // check if mobile device --> then show login form
    if (this.isMobile) {
      if (!this.dialogIsShown) {
        //this.toggleBackdrop('overlay');
        this.toggleShowForm('myForm');
        this.dialogIsShown = true;
      }
    }
  }

  ngOnChanges() {}

  isTouchDevice() {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  }

  animationState = 'out';
  backdropState = 'out';

  toggleBackdrop(divName: string) {
    this.backdropState = this.backdropState === 'out' ? 'in' : 'out';
  }
  toggleShowForm(divName: string) {
    this.animationState = this.animationState === 'out' ? 'in' : 'out';
  }

  onMouseEnter() {
    //console.log('mouse enter');

    if (!this.dialogIsShown) {
      this.toggleBackdrop('overlay');
      this.toggleShowForm('myForm');
      this.dialogIsShown = true;
    }
  }

  @HostListener('mousleave', ['$event'])
  onMouseLeave(event: any) {
    //console.log('mouse leave');

    // window height and width
    let screenWidth = window.innerWidth;
    let screenHeight = window.innerHeight;

    let posX = event.pageX;
    let posY = event.pageY;

    let outOfWindow = false;

    // console.log('coords x: ' + event.pageX + ' y: ' + event.pageY);

    if (posX < 0 || posX > screenWidth) {
      //console.log('out');
      outOfWindow = true;
    }

    if (posY < 0 || posY > screenHeight) {
      //console.log('out');
      outOfWindow = true;
    }

    if (this.dialogIsShown && outOfWindow && !this.fsShowFiles) {
      this.toggleBackdrop('overlay');
      this.toggleShowForm('myForm');
      this.dialogIsShown = false;
    }
  }

  @HostListener('window:focus', ['$event'])
  onFocused() {
    //this.toggleBackdrop('overlay');
    //this.toggleShowForm('myForm');
  }
  @HostListener('window:blur', ['$event'])
  onBlur() {
    //this.toggleShowForm('myForm');
    //this.toggleBackdrop('overlay');
    //this.dialogIsShown = false;
  }

  /*
  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
    console.log('visibility change');
    this.toggleShowForm('myForm');
    this.toggleBackdrop('overlay');
  }
*/

  fullScreen() {
    this.myVideo = document.getElementById('video');
    this.videoContainer = document.getElementById('video-container-id');

    if (document.fullscreenElement) {
      document.exitFullscreen();
      this.myVideo.style.height = '250px';
      this.myVideo.style.width = '100%';
    } else {
      this.videoContainer.requestFullscreen();
      this.videoTag.nativeElement.style.height = '100%';
      this.videoTag.nativeElement.style.width = '100%';
    }
  }

  /* video controls */
  togglePlay() {
    this.playing = !this.playing;
    this.playingIcon = this.playing ? 'play_arrow' : 'pause';

    this.myVideo = document.getElementById('video');
    if (this.myVideo.paused) this.myVideo.play();
    else this.myVideo.pause();
  }

  toggleMute() {
    this.mute = !this.mute;
    this.muteIcon = this.mute ? 'volume_mute' : 'volume_up';
  }

  toggleEye() {
    this.eye = !this.eye;
    this.eyeIcon = this.eye ? 'visibility' : 'visibility_off';
  }

  /*
  forgot - signup - login */

  goGallery() {
    document.location = environment.eplusSystem + '/gallery.php';
  }
}
